/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-d6fybz --style3 --full pb--10" anim={""} name={"uvod"} animS={"5"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"}>
              
              <Subtitle className="subtitle-box fs--48" content={"<span style=\"color: white;\">Profesionální</span><br>"}>
              </Subtitle>

              <Title className="title-box fs--86 w--600 lh--08 mt--16" style={{"maxWidth":800}} content={"<span style=\"color: white;\">Péče o<br>Vaše ruce a nohy</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"informace"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72" style={{"maxWidth":1200}} content={"Upravené nehty<br>a krásné nohy zvýrazní Vaši krásu<br>a ženskost&nbsp;"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":800}} content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"o-nas"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--2" style={{"maxWidth":1300}} anim={"2"} animS={"5"} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/32369/98dc44b6fa884d3d8a0d182f31c94daf_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/32369/98dc44b6fa884d3d8a0d182f31c94daf_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/32369/98dc44b6fa884d3d8a0d182f31c94daf_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/32369/98dc44b6fa884d3d8a0d182f31c94daf_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/32369/98dc44b6fa884d3d8a0d182f31c94daf_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/32369/98dc44b6fa884d3d8a0d182f31c94daf_s=2000x_.jpeg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/32369/502f83bce44649e4bde86f858b595f4d_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/32369/502f83bce44649e4bde86f858b595f4d_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/32369/502f83bce44649e4bde86f858b595f4d_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/32369/502f83bce44649e4bde86f858b595f4d_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/32369/502f83bce44649e4bde86f858b595f4d_s=1400x_.jpeg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/32369/5a6acc07e11246b5bdd8cd6bc73527e8_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/32369/5a6acc07e11246b5bdd8cd6bc73527e8_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/32369/5a6acc07e11246b5bdd8cd6bc73527e8_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/32369/5a6acc07e11246b5bdd8cd6bc73527e8_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/32369/5a6acc07e11246b5bdd8cd6bc73527e8_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/32369/5a6acc07e11246b5bdd8cd6bc73527e8_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/32369/0f845916aad0460da9b9c9940166f358_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/32369/0f845916aad0460da9b9c9940166f358_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/32369/0f845916aad0460da9b9c9940166f358_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/32369/0f845916aad0460da9b9c9940166f358_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/32369/0f845916aad0460da9b9c9940166f358_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/32369/0f845916aad0460da9b9c9940166f358_s=2000x_.jpeg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/32369/8c9a2ba74d0a49259f07d9f03ffd32fd_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/32369/8c9a2ba74d0a49259f07d9f03ffd32fd_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/32369/8c9a2ba74d0a49259f07d9f03ffd32fd_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/32369/8c9a2ba74d0a49259f07d9f03ffd32fd_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/32369/8c9a2ba74d0a49259f07d9f03ffd32fd_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/32369/8c9a2ba74d0a49259f07d9f03ffd32fd_s=2000x_.jpeg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/32369/64ba29a95b724999a6b83c126923c19a_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/32369/64ba29a95b724999a6b83c126923c19a_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/32369/64ba29a95b724999a6b83c126923c19a_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/32369/64ba29a95b724999a6b83c126923c19a_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/32369/64ba29a95b724999a6b83c126923c19a_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"informace"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center" style={{"maxWidth":""}} anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--72" style={{"maxWidth":950}} content={"Vaše ruce a chodidla si zaslouží jen tu&nbsp;nejlepší péči."}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-15z7nop pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex el--3 flex--center" style={{"maxWidth":1420}} anim={""} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Těším se na Vaši návštěvu ve studiu VeNo</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Profesionální</span>"}>
              </Subtitle>

              <Title className="title-box mt--02" content={"<span style=\"color: var(--color-dominant);\">Péče o<br>Vaše ruce a nohy</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-custom-1--95);\"><br><br>Renata Juránková<br>774 709 170<br>Studio VeNo<br>V Horkách 1389/11<br>140 00 Praha 4<br><br></span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">IČ: 60999284<br><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}